import ListItem from "@material-ui/core/ListItem";
import {Link} from "react-router-dom";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";


export default function Item({text, link, onClick, styles, textStyle}) {
  return (
    <ListItem button
              onClick={onClick}
              style={styles}
              component={props => <Link to={link} {...props}/>}>

      <ListItemText primary={text}
                    style={textStyle}
      />
    </ListItem>
  )
}
