import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import {makeStyles, useTheme, withStyles} from '@material-ui/core/styles';
import PhotoList from './PhotoList';
import {setGlobal, useGlobal} from 'reactn';
import DialogAddAlbum from './DialogAddAlbum';
import PhotoPicker from '../PhotoPicker/PhotoPicker';
import {Grid} from '@material-ui/core';
// import DialogShowImage from "./DialogShowImage"; // Import css
import {useHistory, useParams} from 'react-router-dom';
import {find} from 'lodash';
import {isMobileOnly} from 'react-device-detect';
import Fab from '@material-ui/core/Fab';
import 'react-contexify/dist/ReactContexify.min.css';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DeleteIcon from '@material-ui/icons/Delete';
import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import {firestore} from '../../fire';
import {useCollection} from 'react-firebase-hooks/firestore';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import EditNameAlbum from './EditNameAlbum';

const drawerWidth = 180;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    top: 64,
    left: 'auto',
    zIndex: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },

}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...props}
    />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

/**
 * @return {null}
 */

export default function AlbumList(props) {
  const {albumId} = useParams();
  const history = useHistory();
  const {container} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useGlobal('openMobile');
  const [user] = useGlobal('user');
  // const [selectedIndex, setSelectedIndex] = useGlobal('albumSelIndex');
  const [albumList, setAlbumList] = useState([]);
  const [selAlbum, setSelAlbum] = useGlobal('selAlbum');
  // const [albumId] = useGlobal('albumId')
  // const [albumList, loading, error] = useDocument(`funshare-users/${user.uid}`);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOpenEdit = () => {
    setOpenEdit(true);
    setAnchorEl(null);
  };
  const [albumColl, loading, error] = useCollection(
      firestore.collection(`funshare-albums`).
          where('users', 'array-contains', user.uid).
          orderBy('createdAt', 'desc'),
  );

  useEffect(() => {console.log(error);}, [error]);
  useEffect(() => {
    if (!albumColl || albumColl.empty) {
      setAlbumList([]);
      return;
    }
    setAlbumList(albumColl.docs.map(d => ({id: d.id, ...d.data()})));
  }, [albumColl]);

  useEffect(() => {
    if (!albumList.length) return;
    if (!albumId && albumList.length) {
      history.push(`/album/${albumList[0].id}`);
      return;
    }
    setSelAlbum(find(albumList, {id: albumId}));
  }, [albumId, albumList]);

  // async function onAlbumSelected(event, index, id) {
  //   // await setSelectedIndex(index);
  //   // setSelAlbum(albumList.find(alb => {
  //   //   return alb.id = albumId
  //   // }))
  //   // await setGlobal({ album_id: id, lastPage: 1, page: 1 })
  //   if (isMobileOnly) {
  //     setMobileOpen(!mobileOpen)
  //   }
  // }

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const onDelClick = (album) => {
    if (isMobileOnly) {
      setMobileOpen(!mobileOpen);
    }
    confirmAlert({
      title: `Delete album "${album.topic}" ?`,
      message: 'Album đã xoá không thể khôi phục, bạn có chắc chắn muốn xoá không?',
      buttons: [
        {
          label: 'Yes, Xoá',
          onClick: () => {
            firestore.doc(`funshare-albums/${album.id}`).delete().then(() => {
              console.log(`deleted ${album.id}`);
              history.push('/');
            });
          },
        },
        {
          label: 'Ko xoá nữa',
          onClick: () => {
          },
        },
      ],
    });
  };

  const AlbumListComponent = (
      <div>
        <Grid container justify={'space-around'}
              style={{paddingTop: 10, paddingBottom: 10}}
              alignItems={'center'} alignContent={'center'}>
          <Grid item>
            <Typography variant="h6" noWrap
                        style={{textAlign: 'center', margin: 'auto'}}>
              Albums
            </Typography>
          </Grid>
          <Grid item>
            <Fab color={'primary'} aria-label="Add" onClick={
              () => setGlobal({openAddAlbum: true})
            }>
              <AddIcon/>
            </Fab>
          </Grid>
        </Grid>
        <Divider/>
        {
          loading && <CircularProgress/>
        }
        {
          !loading && albumColl && albumColl.empty &&
          <div>Create your first collection now.</div>
        }
        {
          !loading && error &&
          <div>{error.message}</div>
        }
        <List style={{paddingBottom: 100}}>
          {albumList.map((album, index) => (
              <ListItem
                  button
                  key={album.id}
                  selected={albumId === album.id}
                  // onClick={event => onAlbumSelected(event, index, album.id)}
                  onClick={() => {
                    // console.log(album.id)
                    // setAlbumList([])
                    history.replace(`/album/${album.id}`);
                  }}
              >
                <ListItemText primary={album.topic}/>
                {
                  album && (album.users[0] === user.uid) &&
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={handleClick}>
                      <MoreVertIcon/>
                    </IconButton>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                      <StyledMenuItem onClick={() => {
                        onDelClick(album);
                        setAnchorEl(null);
                      }}>
                        <ListItemIcon>
                          <DeleteIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Xóa Album"/>
                      </StyledMenuItem>
                      <StyledMenuItem onClick={onOpenEdit}>
                        <ListItemIcon>
                          <EditIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Sửa Album"/>
                      </StyledMenuItem>
                    </StyledMenu>
                  </ListItemSecondaryAction>
                }
              </ListItem>
          ))}
        </List>

      </div>
  );

  // if (!selAlbum)
  //   return null
  return (
      <div className={classes.root}>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
            >
              {AlbumListComponent}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
            >
              {AlbumListComponent}
            </Drawer>
          </Hidden>
        </nav>

        <Grid container justify={'center'} alignItems={'center'}
              alignContent={'center'}>
          <Grid item>
            <PhotoList/>
          </Grid>
          <Grid item>
            <PhotoPicker/>
          </Grid>
        </Grid>

        <DialogAddAlbum/>
        <EditNameAlbum
            openEdit={openEdit}
            setOpenEdit={setOpenEdit}
        />
      </div>
  );
}

AlbumList.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};
