import Plugin from "@uppy/core/lib/Plugin";
import { v4 } from "uuid";
import { getGlobal } from 'reactn'

export default class FirebaseCloudStorage extends Plugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    if (!opts.storageRef) {
      throw Error(
        "Please provide the root storageRef to be used as option `storageRef`. See https://firebase.google.com/docs/storage/web/upload-files"
      );
    }
    this.type = "uploader";
    this.id = "FirebaseCloudStorage";
    this.title = "Firebase Cloud Storage";
    this.storageRef = opts.storageRef;
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds) {
    return Promise.all(
      fileIds.map(id => {
        return new Promise((resolve, reject) => {
          const file = this.uppy.getFile(id);
          const selAlbum = getGlobal().selAlbum
          if (!file) return reject('file does not exists')
          if (!selAlbum) return reject('no album selected')
          const refId = v4();

          const fileRef = this.storageRef.child(`${selAlbum.id}/${refId}`);
          console.log(`-- uploading ${selAlbum.id}/${refId}`)
          const metaData = {
            cacheControl: 'public,max-age=31556926',// 1 nam
            contentType: file.type
          };
          this.uppy.emit("upload-started", file);
          const uploadTask = fileRef.put(file.data, metaData);
          uploadTask.on(
            "state_changed",
            snapshot => {
              const progressInfo = {
                uploader: this,
                bytesUploaded: snapshot.bytesTransferred,
                bytesTotal: snapshot.totalBytes
              };
              this.uppy.emit("upload-progress", file, progressInfo);
            },
            error => {
              this.uppy.emit("upload-error", file, error);
              reject(error);
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(downloadUrl => {
                const file = this.uppy.getFile(id);
                if (!file) return reject('file does not exists')
                file.downloadUrl = downloadUrl;
                this.uppy.emit(
                  "upload-success",
                  file,
                  uploadTask.snapshot,
                  downloadUrl
                );
                resolve();
              });
            }
          );
        });
      })
    );
  }

  install() {
    this.uppy.addUploader(this.uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this.uploadFile);
  }
}
