import { useParams } from 'react-router-dom'
import React, { useEffect, useState } from "react";
import { firestore } from "../../fire";
import firebase from "firebase/app";
import { useGlobal } from 'reactn'
import { CircularProgress } from "@material-ui/core";

/**
 * @return {null}
 */
function Share() {
  const { albumId, code } = useParams()
  // console.log({ albumId, code })
  const [user] = useGlobal('user')
  const [error, setError] = useState(null)
  const [redirect, setRedirect] = useState(null)

  useEffect(() => {
    if (!albumId || !code) {
      setError('dữ liệu không phù hợp.')
      return;
    }

    firestore
      .doc(`funshare-albums/${albumId}/shareColl/${code}`)
      .get()
      .then(d => {
          if (!d.exists) {
            setError('dữ liệu không tồn tại!')
            return;
          }
          firestore
            .doc(`funshare-albums/${albumId}`)
            .set({
              users: firebase.firestore.FieldValue.arrayUnion(user.uid),
            }, { merge: true })
            .then(() => {
              firestore
                .doc(`funshare-albums/${albumId}/userColl/${user.uid}`)
                .set(user, { merge: true })
                .then(() => {
                  setRedirect(`/album/${albumId}`)
                })
            })
            .catch((e) => setError(e.message))
        }
      )
      .catch((e) => setError(e.message))
  }, [albumId, code])

  if (error) {
    alert(error)
    return <div><a href="/">Go Home</a></div>
  }

  if (redirect) {
    window.location = redirect
    return null
  }
  return <CircularProgress/>
}

export default Share;
