import React, {useEffect, useState} from 'react';
import {getGlobal, useGlobal} from 'reactn'
import axios from 'axios'
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import {firestore} from "../../fire";
import {useCollection} from "react-firebase-hooks/firestore";
import Avatar from "@material-ui/core/Avatar";
import DeleteUserDialog from "./DeleteUserDialog";

export const getUsers = async () => {
  const album_id = getGlobal().album_id
  if (!album_id) return []
  try {
    const { data: { album } } = await axios.get('/albums/users', {
      params: { album_id }
    })
    return album.users
  } catch (e) {
    return Promise.reject(e)
  } finally {

  }

}

export default function UserList() {
  const [selAlbum] = useGlobal('selAlbum')
  const [authUser] = useGlobal('user')
  const [isAlbumOwner, setIsAlbumOwner] = useState(false)
  const [users, setUsers] = useGlobal('users')
  const [userColl, loading, error] = useCollection(
    !selAlbum ? null :
      firestore
        .collection(`funshare-albums/${selAlbum.id}/userColl`)
  )
  useEffect(() => {
    if (userColl && !userColl.empty) {
      setUsers(userColl.docs.map(d => ({ id: d.id, ...d.data() })))
    }
  }, [userColl])
  useEffect(() => {
    if (selAlbum && authUser) {
      setIsAlbumOwner(authUser.uid === selAlbum.users[0])
    }
  }, [authUser, selAlbum])

  const DeletableUserChip = ({ user }) => {
    return (
      <Chip variant="outlined"
            label={user.displayName}
            color="primary"
            size="small"
            avatar={<Avatar alt={user.uid} src={user.photoURL}/>}
            clickable
            deleteIcon={<DeleteUserDialog user={user} selAlbum={selAlbum}/>}
            onDelete={() => {
              console.log(`delete`)
            }}
      />
    )
  }
  const NoDeletableUserChip = ({ user }) => {
    return (
      <Chip variant="outlined"
            label={user.displayName} color="primary"
            size="small"
            avatar={<Avatar alt={user.uid} src={user.photoURL}/>}
            clickable
      />
    )
  }

  return (
    <Grid container style={{height: "100px", overflow: "auto"}}>
      {users.map(user => {
        const deletable = isAlbumOwner

        return (
          <Grid item key={user.uid} style={{ padding: 2 }}>
            {
              deletable &&
              <DeletableUserChip user={user}/>
            }
            {
              !deletable &&
              <NoDeletableUserChip user={user}/>
            }
          </Grid>
        );
      })}
    </Grid>
  );
}
