import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {blue} from '@material-ui/core/colors';
import UserList from "../Album/UserList";
import {DialogContent} from "@material-ui/core";

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function Member({openMember, setOpenMember}) {
  const classes = useStyles();


  const handleClose = () => {
    setOpenMember(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={openMember}>
      <DialogTitle id="simple-dialog-title">Danh sách thành viên tham gia Album</DialogTitle>
      <DialogContent>
        <UserList/>
      </DialogContent>
    </Dialog>
  );
}




