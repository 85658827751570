import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import {useGlobal} from "reactn";
// import Carousel, {ModalGateway,Modal} from 'react-images';
import ImgsViewer from 'react-images-viewer'


export default function DialogShowImage({open, photoIndex, onClose, setSelectedPhotoIndex}) {
  // const [open, setOpen] = useGlobal('openImages');
  const [photos] = useGlobal('photos');
  const [images, setImages] = useState([])
  // const [indexImages, setIndexImages] = useGlobal('indexImages')

  useEffect(() => {
    let arrPhoto = photos.map(p => {
      return {
        src: p.downloadUrl,
        caption: `${p.name} - by ${p.user.name}`,
      }
    })
    setImages(arrPhoto)
  }, [photos])
  return (
    <div>
      <ImgsViewer
        imgs={images}
        isOpen={open}
        currImg={photoIndex}
        onClickPrev={() => setSelectedPhotoIndex(photoIndex - 1)}
        onClickNext={() => setSelectedPhotoIndex(photoIndex + 1)}
        onClickThumbnail={idx => setSelectedPhotoIndex(idx)}
        onClose={onClose}
        backdropCloseable={true}
        showThumbnails
      />
      {/*<ModalGateway>*/}
      {/*{open ? (*/}
      {/*<Modal onClose={()=>setOpen(!open)}>*/}
      {/*<Carousel views={images}  currentIndex={indexImages}/>*/}
      {/*</Modal>*/}
      {/*) : null}*/}
      {/*</ModalGateway>*/}
    </div>
  );
}
// DialogShowImage.propTypes = {
//     fullScreen: PropTypes.bool.isRequired,
// };
