import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from '@material-ui/icons/Close';
import Index from "../Profile/Profile";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}))
export default function UserInfo({openUserInfo, setOpenUserInfo}) {
  const classes = useStyles();



  const handleClose = () => {
    setOpenUserInfo(false);
  };

  return (
    <div>
      <Dialog fullScreen open={openUserInfo} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Thông tin cá nhân
            </Typography>
          </Toolbar>
        </AppBar>
        <Index/>
      </Dialog>
    </div>
  );
}




