import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {useGlobal, setGlobal} from "reactn";
import Grid from "@material-ui/core/Grid";
import axios from 'axios'
import {CREATE_USER} from "../../config/url";
import store from 'store'

export default function CreateUser() {
    const [openCreateUser, setOpenCreateUser] = useGlobal('openCreateUser')
    const [name, setName] = useState('')

    function createUser() {
        if (name) {
            axios.post(CREATE_USER, {
                name: name
            }).then(res => {
                setGlobal({user: {...res.data, user_id: res.data.id}})
                store.set('user_id', res.data.id)
                store.set('user_name', res.data.name)
                console.log(res.data)
            })
            setOpenCreateUser(false);
        }

    }

    return (
        <div>
            <Dialog open={openCreateUser} aria-labelledby="form-dialog-title">
                {/*<DialogTitle id="responsive-dialog-title">{"Tạo Album"}</DialogTitle>*/}
                {/*<Divider/>*/}
                <DialogContent>
                    <Grid container style={{alignItems: 'center', textAlign: 'right'}}>

                        <TextField
                            id="outlined-email-input"
                            placeholder={'My Nick Name'}
                            margin="normal"
                            variant="outlined"
                            onChange={(e) => setName((e.target.value))}
                            autoFocus={true}
                        />

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={createUser}
                        color="primary"
                        disabled={!name}
                    >
                        Tạo
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
