import React, {useEffect} from "react";
// And their styles (for UI plugins)
import {getGlobal, useGlobal} from 'reactn'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {firestore} from '../../fire'
import uppy from "./UppyUploader";
import DashboardModal from '@uppy/react/lib/DashboardModal'
import {useParams} from 'react-router-dom'

let lastDownloadUrl
const PhotoPicker = () => {
  const {albumId} = useParams()

  const [openUppy, setOpenUppy] = useGlobal('openUppy')
  const [selAlbum] = useGlobal('selAlbum')
  const [user] = useGlobal('user')

  useEffect(() => {
    if (!uppy || !user) return

    uppy
      .on('upload-success', (file, response) => {

        const selAlbum = getGlobal().selAlbum
        if (!selAlbum)
          return;
        const {name, extension, type, size, downloadUrl} = file
        if (lastDownloadUrl === downloadUrl)
          return;
        lastDownloadUrl = downloadUrl
        firestore
          .collection(`funshare-albums/${selAlbum.id}/photoColl`)
          .add({
            uid: user.uid,
            name, extension, type, size, downloadUrl,
            createdAt: new Date,
            user: {name: user.displayName, email: user.email, photoURL: user.photoURL}
          })
          .then(d => {
            console.log(`added ${d.id}`)
          })
      })
      .on('complete', (result) => {
        // getAlbumList()
      })

    return () => {
      uppy.reset()
    }

  }, [user, selAlbum])

  return (
    <div>
      {selAlbum &&
      <Fab
        color="primary"
        aria-label="Add"
        onClick={() => setOpenUppy(true)}
        style={{position: 'fixed', bottom: 30, zIndex: 1, margin: 'auto', left: 0, right: 0}}
      >
        <AddIcon/>
      </Fab>
      }
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutside
        plugins={['Webcam']}
        open={openUppy}
        onRequestClose={() => {
          // uppy.reset()
          setOpenUppy(false)
        }}
        style={{zIndex: 1400}}
      />
    </div>
  )
}

export default PhotoPicker
