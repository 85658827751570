import React from 'react';
import AlbumList from "../Album/AlbumList";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core";
import {useGlobal} from "reactn";
import UserList from "../Album/UserList";
import ChatHome from "../Chatgroup/ChatHome";

const useStyles = makeStyles(theme => ({

  chat: {
    position: 'fixed',
    right: 10,
    textAlign: 'center',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    height: "calc(100vh - 50px)",
    bottom: 0,
    width: "24%",
    [theme.breakpoints.down('sm')]: {
      // position: 'static',
      // height: 'auto',
      // width: 'auto',
      // paddingTop: 40
      display: 'none'
    }
  },
}));
const Main = () => {
  const classes = useStyles();
  const [album_id] = useGlobal('album_id')
  return (
    <div>
      <Grid container>
        <Grid item sm={12} md={9}>
          <AlbumList/>
        </Grid>
        <Grid item sm={12} md={3}>
          <div className={classes.chat}>
            <UserList/>
            {/*<ChatScreen/>*/}
            <ChatHome/>
          </div>

          {/*<Test/>*/}
        </Grid>
      </Grid>
    </div>
  );
};

export default Main;
