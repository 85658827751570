import {Button} from "@material-ui/core";
import React from "react";
import {Link} from "react-router-dom";

export default function TopbarButton({text, to, onClick}) {
  return (
    <Button style={styles.btn}
            component={props => <Link href={to} {...props}/>}
            to={to}
            onClick={onClick}
    >
      {text}
    </Button>
  )
}
const styles = {
  btn: {color: 'white'}
}
