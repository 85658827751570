import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useGlobal } from 'reactn'
import QrCode from '../QRCode'
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Close from '@material-ui/icons/CancelRounded'
import { isMobileOnly } from "react-device-detect";

export default function QRCodeDialog() {
  const [open, setOpen] = useGlobal('openDialogScanner');

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      <Dialog
        fullScreen={isMobileOnly}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Paper style={{ padding: 24 }}>

          <Grid container direction={"column"} justify={"center"}
                alignItems={"center"}
                alignContent={"center"}>

            <Grid item>
              <QrCode/>
            </Grid>
            <Grid item>
              <Button onClick={() => setOpen(false)} color={"secondary"}>
                <Close/>
              </Button>
            </Grid>

          </Grid>
        </Paper>
      </Dialog>
    </div>
  );
}
