import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom'
import Home from './components/Home/Home'
import {useGlobal} from "reactn";
import Share from "./components/Share";
import CreateUser from "./components/Home/CreateUser";
import Scanner from "./components/QRCode/Scanner";
import GotoDialog from "./components/QRCode/GotoDialog";
import DownloadDialog from "./components/MenuAppBar/DownloadDialog";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Login from "./components/Login/Login";
import {useAuthState} from 'react-firebase-hooks/auth';
import {useDocument} from "react-firebase-hooks/firestore";
import {auth, db} from './fire/index'
import LinearProgress from "@material-ui/core/LinearProgress";
import Profile from "./components/Profile/Profile";


/**
 * @return {null}
 */

export default function App() {

  const [user, initialising, error] = useAuthState(auth);
  const [, setAuthUser] = useGlobal('user')
  const [didCheckAuth, setDidCheckAuth] = useState(false)
  const [userDoc] = useDocument(
    (user && user.uid) ?
      db.doc(`users/${user.uid}`)
      : null
  )
  useEffect(() => {
    if (initialising) return;
    const u = {
      ...user?.toJSON(),
      ...userDoc?.data()
    }
    setAuthUser(u, () => {
      setDidCheckAuth(true)
    })
  }, [user, userDoc, initialising])

  if (!didCheckAuth) return <LinearProgress/>
  if (error) {
    alert(error.message)
    return null
  }
  if (didCheckAuth && !user) {
    if (!window.location.pathname.includes('login')) {
      localStorage.setItem('redirectUrl', window.location.href)
      window.location = '/login'
    }
  }
  if (didCheckAuth && user) {
    const redirectUrl = localStorage.getItem('redirectUrl')
    if (redirectUrl) {
      localStorage.removeItem('redirectUrl')
      window.location = redirectUrl
    }
  }

  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path="/share/:albumId?/:code?" component={Share}/>
        <Route path="/login" component={Login}/>
        <Route path="/album/:albumId?" component={Home}/>
        <Route path="/profile" component={Profile}/>
        {/*<Route path="*" component={NotFoundComponent}/>*/}
      </Switch>
      <CreateUser/>
      <Scanner/>
      <GotoDialog/>
      <DownloadDialog/>

      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />

    </div>


  );
}

function NotFoundComponent() {
  return <>
    <div>NOT FOUND :-(</div>
    <div><a href="/">GO HOME</a></div>
  </>
}
