// import 'date-fns';
import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {db, storage} from '../../fire';
import 'moment/locale/vi';
import firebase from 'firebase/app';
import Avatar from '@material-ui/core/Avatar';
import {toast} from 'react-toastify';
import {auth} from '../../fire/index';

// const borderBoxProps = {
//   bgcolor: 'background.paper',
//   borderColor: 'text.primary',
//   m: 1,
//   border: 2,
//   padding: 2
//   // style: { width: '5rem', height: '5rem' },
// };

const styles = theme => ({
  root: {
    margin: 'auto',
    marginTop: '40px',
    width: '50%',
    borderRadius: 16,
    border: '1px solid gray',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  formControl: {
    margin: theme.spacing(),
  },
  photoURL: {
    marginTop: '10px',
    height: '100px',
    width: '100px',
    border: '1px dashed black',
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  input: {
    display: 'none',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 300,
  },
  buttonWeb: {
    display: 'block',
    paddingBottom: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonMobie: {
    paddingBottom: 10,
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
});

function Profile({classes, history}) {
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [photoURL, setPhotoURL] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const authUser = auth.currentUser;

  useEffect(() => {
    if (authUser) {
      setEmail(authUser.email);
      setDisplayName(authUser.displayName);
      setPhotoURL(authUser.photoURL);
    }

  }, [authUser]);

  async function onChangeAvatar(event) {
    let file = event.target.files[0];

    const imagesRef = storage.ref().child(`users/${authUser.uid}/avatar`);

    // Upload file and metadata to the object 'images/mountains.jpg'
    const uploadTask = imagesRef.put(file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function(snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = Math.floor(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          // console.log('Upload is ' + progress + '% done');
          setUploadProgress(progress);

        }, function(error) {
          console.log(error);
        }, function() {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function(photoURL) {
            console.log('File available at', photoURL);
            setPhotoURL(photoURL);
          });
        });
  }

  async function onSave() {
    setLoading(true);
    const data = {
      email,
      displayName,
      photoURL,
    };

    try {
      await authUser.updateProfile({displayName, photoURL});
      await db.doc(`users/${authUser.uid}`).
          set({displayName, photoURL}, {merge: true});
      // await setProfiledata(data);
      // await auth.updateCurrentUser(authUser)
      // await authUser.getIdToken(true);
      // await setAuthUser(auth.currentUser)
      toast.success('update success');
      history.push('/');
      // setUpdatedText('update success')
    } catch (e) {
      toast.error(e.message);
      // setUpdatedText('update error')
    } finally {
      setLoading(false);
    }

  }

  const onCancel = () => {
    history.push('/');
  };
  return (
      <div className={classes.root}>
        <Grid container
              direction={'column'}
              alignItems={'center'}
              alignContent={'center'}
        >
          <Grid item>
            <TextField
                label="Email"
                type="email"
                value={email}
                className={classes.textField}
            />
          </Grid>
          <Grid item>
            <TextField
                label="Full Name"
                value={displayName}
                className={classes.textField}
                onChange={(e) => setDisplayName(e.target.value)}
            />
          </Grid>
          <Grid item>
            <FormControl className={classes.formControl}>
              <Grid container justify="center" alignItems="center"
                    direction={'column'}>
                <input
                    accept="photoURL/*"
                    className={classes.input}
                    id="text-button-file"
                    type="file"
                    onChange={onChangeAvatar}
                />
                <label htmlFor="text-button-file">
                  <Button component="span" className={classes.button}
                          color={'primary'}>
                    Upload Avatar
                  </Button>
                </label>
                <div
                    hidden={!uploadProgress || parseInt(uploadProgress) ===
                    100}>uploading... {uploadProgress}%
                </div>
                {photoURL &&
                <Avatar alt="photoURL" src={photoURL}
                        className={classes.bigAvatar}/>
                }
              </Grid>
            </FormControl>
          </Grid>
          <div className={classes.buttonMobie}>
            <Grid item xs={6}>
              <Button
                  variant={'contained'}
                  color={'primary'}
                  style={{width: 200}}
                  onClick={onSave}
                  disabled={loading}
              >
                Lưu
              </Button>
            </Grid>
          </div>
          <div className={classes.buttonWeb}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    style={{width: 150}}
                    onClick={onSave}
                    disabled={loading}
                >
                  Lưu
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    style={{width: 150}}
                    onClick={onCancel}
                    disabled={loading}
                >
                  Hủy
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
  );
}

export default withRouter(withStyles(styles)(Profile));
