import React from 'react';
import MenuAppBar from "../MenuAppBar/MenuAppBar";
import Main from "../Content/Main";

const Home = () => {
  // const { albumId } = useParams()
  // useEffect(() => {
  //   console.log({ albumId })
  //   setGlobal({ albumId })
  // }, [albumId])
  return (
    <div>
      <MenuAppBar/>
      <Main/>

      {/*<DialogShowImage/>*/}
    </div>
  );
};

export default Home;
