import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CloseIcon from '@material-ui/icons/Close';
import ChatHome from "../Chatgroup/ChatHome";


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'fixed',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },

}))
export default function UserInfo({openChat, setOpenChat}) {
  const classes = useStyles();


  const handleClose = () => {
    setOpenChat(false);
  };

  return (
    <div>
      <Dialog fullScreen open={openChat} onClose={handleClose}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon/>
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Group Chat
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{paddingTop: 58}}>
          <ChatHome/>
        </div>
      </Dialog>
    </div>
  );
}




