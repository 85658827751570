import React from "react";
import { withRouter } from 'react-router-dom'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import Grid from "@material-ui/core/Grid";
import firebase from "firebase/app";

const uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      return true;
    },
  },
  signInFlow: 'popup',
  signInSuccessUrl: '/',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
};

/**
 * @return {null}
 */
function Login({ history }) {
  return (
    <>
      <Grid container
            justify={"center"}
            alignItems={"center"}
            direction={"column"}
      >
        <p>ĐĂNG NHẬP</p>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
      </Grid>
    </>
  );
}

export default withRouter(Login)
