import React, {useCallback, useEffect, useMemo, useState} from 'react';
import 'react-image-lightbox/style.css';
import Columns from 'react-columns';
import {isMobileOnly} from 'react-device-detect';
import 'video-react/dist/video-react.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {makeStyles} from '@material-ui/core';
import DelePhotoDialog from './deletePhoto';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogShowImage from './DialogShowImage';
import {useCollection} from 'react-firebase-hooks/firestore';
import {firestore} from '../../fire';
import {findIndex} from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import {confirmAlert} from 'react-confirm-alert';
import {useParams} from 'react-router-dom';
import {useGlobal} from 'reactn';

const useStyles = makeStyles(theme => ({
  img: {
    backgroundSize: '100% 100%',
    width: '100%',
    minWidth: 100,
  },
  iconDelete: {
    position: 'absolute',
    top: 10,
    right: 5,
  },
}));

function PhotoList() {
  const classes = useStyles();
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [openDialogShowImage, setOpenDialogShowImage] = useState(false);
  const [user] = useGlobal('user');

  const {albumId} = useParams();
  const [selAlbum] = useGlobal('selAlbum');
  // const [photos, setPhotos] = useState([])
  const [photos, setPhotos] = useGlobal('photos');
  const [photoColl, pLoading, pError] = useCollection(
      selAlbum?.id ? firestore.collection(
          `funshare-albums/${selAlbum?.id}/photoColl`).
              orderBy('createdAt', 'desc')
          : null,
  );

  useEffect(() => {
    if (!photoColl || photoColl.empty) {
      setPhotos([]);
      return;
    }
    setPhotos(photoColl.docs.map(d => ({id: d.id, ...d.data()})));
  }, [photoColl]);

  const onDeleteImage = (photo) => {
    confirmAlert({
      title: `Bạn muốn xóa ảnh này ?`,
      message: 'Ảnh đã xoá sẻ không thể khôi phục, bạn có chắc chắn muốn xoá không?',
      buttons: [
        {
          label: 'Yes, Xoá',
          onClick: () => {
            firestore.doc(`funshare-albums/${albumId}`).
                collection('photoColl').
                doc(photo.id).
                delete().
                then(() => {
                  console.log('Document successfully deleted!');
                });
          },
        },
        {
          label: 'Ko xoá nữa',
          onClick: () => {
          },
        },
      ],
    });

  };

  const renderImages = useCallback(() => {
    return photos.map((photo, index) => {
      if (
          photo.extension === 'jpg' ||
          photo.extension === 'png' ||
          photo.extension === 'jpeg' ||
          photo.extension === 'gif'
      )
        return (
            <div key={photo.id}
                 style={{marginRight: 3, position: 'relative'}}
            >
              <img
                  src={photo.downloadUrl}
                  className={classes.img}
                  onClick={() => onClickImage(photo)}
                  alt={photo.downloadUrl}
              />
              {user.uid === photo.uid &&
              <div>
                <DeleteIcon
                    onClick={() => onDeleteImage(photo)}
                    className={classes.iconDelete}
                    color={'primary'}/>
              </div>
              }
            </div>
        );
      return null;
    });
  }, [photos]);
  const onClickImage = (photo) => {
    // setGlobal({ indexImages: index, openImages: true })
    setSelectedPhotoIndex(findIndex(photos, {id: photo.id}));

    setOpenDialogShowImage(true);
  };

  if (pLoading) return <CircularProgress/>;
  if (pError) return <div>{pError.message}</div>;

  return (
      <>

        <Columns columns={isMobileOnly ? 3 : 5}
                 gap={'3'}
        >
          {renderImages()}
        </Columns>

        <DelePhotoDialog/>
        <DialogShowImage
            open={openDialogShowImage}
            photoIndex={selectedPhotoIndex}
            setSelectedPhotoIndex={setSelectedPhotoIndex}
            onClose={() => setOpenDialogShowImage(false)}/>
      </>
  );
}

export default PhotoList;
