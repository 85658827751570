import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from 'react-router-dom'
import './setGlobal'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';
import axios from 'axios'
import { API_URL } from "./config/url";
import './fire'

// console.log({ API_URL })
axios.defaults.baseURL = `${API_URL}`

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#ff5c33" }, // Purple and green play nicely together.
      secondary: { main: pink['A400'] }, // This is just green.A700 as hex.
    },
  }
);

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Router history={createBrowserHistory()}>
      <App/>
    </Router>
  </MuiThemeProvider>
  ,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
