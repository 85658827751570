import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useGlobal } from 'reactn'
import multiDownload from 'multi-download'

export default function DownloadDialog() {
  const [open, setOpen] = useGlobal("openDownload")
  const [photos] = useGlobal("photos")

  function handleClose() {
    setOpen(false)
  }

  function handleDownload() {
    handleClose()
    if (photos.length !== 0) {
      multiDownload(photos.map(photo => photo.downloadUrl))
    }

  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id="alert-dialog-title">{"Download Album"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {
              photos.length !== 0 ? `Bạn có muốn tải xuống ${photos.length} ảnh  ? ` :
                "No images"
            }

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Calcel
          </Button>
          <Button
            onClick={handleDownload}
            color="primary"
            autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
