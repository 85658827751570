import {getGlobal, setGlobal} from 'reactn'
import query from "./common/query";

window.globalData = async () => {
  return getGlobal();
}
// addCallback(global => {
//     if (process.env.NODE_ENV === 'development') console.log(global)
// })

setGlobal(
  {
    openAddAlbum: false,
    openImages: false,
    indexImages: 0,
    openMobile: false,
    openUppy: false,
    openMessage: false,
    openCreateUser: false,
    user: null,
    albumList: [],
    album_id: query('album'),
    albumSelIndex: null,
    photos: [],
    messages: [],
    openScanner: false,
    link: '',
    openDialogScanner: false,
    openLinkDialog: false,
    endMessageEL: null,
    openDownload: false,
    users: [],
      authUser: null,
    opendeletePhoto: false,
    photoSelected: '',
    anchoEl: null,
    page: 1,
    lastPage: 1,
    selAlbum: null
  }
)
