import React, {useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CenterFocusWeak from '@material-ui/icons/CenterFocusWeak';
import MenuIcon from '@material-ui/icons/Menu';
import {setGlobal, useGlobal} from 'reactn';
import QRCodeDialog from '../QRCode/QRCodeDialog';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ChatIcon from '@material-ui/icons/Chat';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import InfoIcon from '@material-ui/icons/Info';
import UserInfo from '../MenuRight/UserInfo';
import Member from '../MenuRight/Member';
import Chat from '../MenuRight/Chat';
import UserAvatar from '../Profile/UserAvatar';
import TopbarButton from '../Profile/TopbarButton';
import Item from '../Profile/Item';
import firebase from 'firebase/app';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 64,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  margin: {
    marginLeft: 'auto',
  },
  menuRight: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  userRight: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuRightWeb: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        {...props}
    />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function MenuAppBar() {
  const classes = useStyles();
  const [authUser] = useGlobal('user');
  const [mobileOpen, setMobileOpen] = useGlobal('openMobile');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [openMember, setOpenMember] = useState(false);
  const [openChat, setOpenChat] = useState(false);
  const [anchorAvatar, setAnChorAvatar] = useState(null);
  const isMenuOpen = Boolean(anchorAvatar);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClickOpen() {
    setGlobal({openDialogScanner: true});
  }

  function handleDrawerOpen() {
    setGlobal({openMenuRight: true});
  }

  const onLogout = async () => {
    firebase.auth().signOut();
  };
  const handleProfileMenuClose = event => {
    setAnChorAvatar(null);
  };
  const handleProfileMenuOpen = event => {
    setAnChorAvatar(event.currentTarget);
  };
  const renderAvatarMenu = (
      authUser &&
      <Menu
          anchorEl={anchorAvatar}
          anchorOrigin={{vertical: 'top', horizontal: 'right'}}
          transformOrigin={{vertical: 'top', horizontal: 'right'}}
          open={Boolean(anchorAvatar)}
          onClose={handleProfileMenuClose}
      >
        <Item link={'/profile'} text={'Thông tin cá nhân'}/>
        <Item onClick={onLogout} text={'Đăng xuất'}/>
      </Menu>
  );

  return (
      <div className={classes.root}>
        <AppBar position={'fixed'}>
          <Toolbar style={{
            backgroundColor: '#ff5c33',
            minHeight: '48px',
            position: 'relative',
          }}>
            <IconButton
                color="inherit"
                aria-label="Open drawer"
                edge="start"
                onClick={() => setMobileOpen(!mobileOpen)}
                className={classes.menuButton}
            >
              <MenuIcon/>
            </IconButton>
            <Typography variant="h6" color="inherit">
              FunShare
            </Typography>
            <div className={classes.menuRightWeb}>
              <IconButton
                  aria-label="qr"
                  color="inherit"
                  onClick={handleClickOpen}
              >
                <CenterFocusWeak/>
              </IconButton>
              {authUser ?
                  <IconButton
                      className={classes.userRight}
                      aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                  >
                    <UserAvatar imgUrl={authUser.photoURL}
                                text={authUser.displayName}/>

                  </IconButton>
                  :
                  <TopbarButton text={'Đăng nhập'} to={'/login'}/>
              }
              <IconButton
                  color="inherit"
                  aria-label="menu-right"
                  className={classes.menuRight}
                  onClick={handleClick}
              >
                <MoreVertIcon/>
              </IconButton>
            </div>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
              <StyledMenuItem onClick={() => {
                setOpenUserInfo(true);
                setAnchorEl(null);
              }}>
                <ListItemIcon>
                  <InfoIcon/>
                </ListItemIcon>
                <ListItemText primary="Thông tin"/>
              </StyledMenuItem>
              <StyledMenuItem onClick={() => {
                setOpenMember(true);
                setAnchorEl(null);
              }}>
                <ListItemIcon>
                  <PeopleOutlineIcon/>
                </ListItemIcon>
                <ListItemText primary="Thành viên"/>
              </StyledMenuItem>
              <StyledMenuItem onClick={() => {
                setOpenChat(true);
                setAnchorEl(null);
              }}>
                <ListItemIcon>
                  <ChatIcon/>
                </ListItemIcon>
                <ListItemText primary="Group Chat"/>
              </StyledMenuItem>
            </StyledMenu>

            <QRCodeDialog/>
            <Member
                openMember={openMember}
                setOpenMember={setOpenMember}
            />
            <UserInfo
                openUserInfo={openUserInfo}
                setOpenUserInfo={setOpenUserInfo}
            />
            <Chat
                openChat={openChat}
                setOpenChat={setOpenChat}
            />
          </Toolbar>
        </AppBar>
        {renderAvatarMenu}
      </div>
  );
}

export default MenuAppBar;
