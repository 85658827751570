// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app'
// Add the Firebase products that you want to use
import "firebase/auth"
import "firebase/firestore"
import "firebase/storage"

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1okh61Zk2waQB0ZOoln0mn8nWzk4prbo",
  authDomain: "photoshare-772ec.firebaseapp.com",
  databaseURL: "https://photoshare-772ec.firebaseio.com",
  projectId: "photoshare-772ec",
  storageBucket: "photoshare-772ec.appspot.com",
  messagingSenderId: "896649150025",
  appId: "1:896649150025:web:9257aa768000a8ecc38998",
  measurementId: "G-6EQFJWVYV3"
};

// Initialize Firebase
const defaultProject = firebase.initializeApp(firebaseConfig);

export const auth = defaultProject.auth()
export const firestore = defaultProject.firestore()
export const db = firestore
export const storage = defaultProject.storage();

export default defaultProject
if (window) window.firebase = firebase
