import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { setGlobal, useGlobal } from "reactn";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import DialogTitle from "@material-ui/core/DialogTitle";
import { isMobileOnly } from 'react-device-detect'
import { db } from '../../fire/index'
import firebase from 'firebase/app'
import { useHistory } from 'react-router-dom'

export default function DialogAddAlbum() {
  const history = useHistory()
  const [open, setOpen] = useGlobal('openAddAlbum');
  const [loading, setLoading] = useState(false);
  const [user] = useGlobal('user');
  const [name, setName] = useState('');

  function handleClose() {
    setOpen(false);
  }

  async function addAlbum() {
    setLoading(true)
    try {
      // const { data } = await axios.post(ADD_ALBUM, {
      //   topic: name,
      //   user_id: user.user_id
      // })
      // await getAlbumList(data.id)
      /*
      // db
      funshare-albums
        - albumId
          - topic
          - users[]
          - photoColl
            - photoId
              - uid
              - path
      funshare-users
        - user
          - albums[]

      // storage
      funshare-albums
        - albumId
          - photoFiles
       */
      const alb = await db.collection('funshare-albums')
        .add({
          topic: name,
          users: firebase.firestore.FieldValue.arrayUnion(user.uid),
          createdAt: new Date()
        })

      db
        .doc(`funshare-albums/${alb.id}/userColl/${user.uid}`)
        .set(user, { merge: true })

      await db.doc(`funshare-users/${user.uid}`)
        .set(
          {
            albums: firebase.firestore.FieldValue.arrayUnion(alb.id)
          },
          { merge: true }
        )

      console.log(`created album ${alb.id}`)
      history.push(`/album/${alb.id}`)
      setOpen(false);

      setLoading(false)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log('enter press here! ')
      addAlbum()
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="responsive-dialog-title">{"Tạo Album"}</DialogTitle>
        <Divider/>
        <DialogContent>
          <Grid container justify={"space-between"} alignContent={"center"}
                spacing={2}
                alignItems={"center"}>
            <Grid item>
              <TextField
                onKeyPress={onKeyPress}
                autoFocus={true}
                id="outlined-email-input"
                placeholder={"Tên Album"}
                margin="normal"
                variant="outlined"
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            {!isMobileOnly &&
            <Grid item>
              <Button onClick={() => setGlobal({ openScanner: true, openAddAlbum: false })}
                      color="primary"
                      variant={"contained"}
              >
                QRCode
              </Button>
            </Grid>
            }

          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justify={"space-between"}>
            <Grid item>
              <Button onClick={handleClose} color="secondary"
                      disabled={loading}
              >
                Hủy
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={addAlbum} color="primary" variant={"contained"}
                      disabled={loading}
              >
                Tạo
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
