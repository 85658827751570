import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-scanner'
import { Dialog } from '@material-ui/core'
import { setGlobal, useGlobal } from 'reactn'
import { FUNSHARE_IO } from "../../config/url";

function Scanner(props) {
    const [delay] = useState(300)
    const [result, setResult] = useState('No result')
    const [open, setOpen] = useGlobal("openScanner")

    useEffect(() => {
        if (result && result.includes(`${FUNSHARE_IO}/share`)) {
            setGlobal({
                link: result,
                openLinkDialog: true,
                openScanner: false
            })
        }
    }, [result])

    function handleScan(data) {
        setResult(data)
    }

    function handleError(err) {
        console.error(err)
    }

    const previewStyle = {
        height: 240,
        width: 320,
    }

    return (
        <div>
            <Dialog open={open} onClose={() => setOpen(false)}>
                {open &&
                <div>
                    <QrReader
                        delay={delay}
                        style={previewStyle}
                        onError={handleError}
                        onScan={handleScan}
                    />
                </div>
                }

            </Dialog>
        </div>
    )

}

export default Scanner
