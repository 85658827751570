import React, {useEffect, useRef, useState} from 'react';
import {
  Avatar,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {Send} from '@material-ui/icons';
import {auth, firestore} from '../../fire';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import _ from 'lodash';
import {useParams} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  contentMe: {
    textAlign: 'right',
    margin: 5,
    '& span': {
      borderRadius: 12,
      padding: 10,
      backgroundColor: ' blue',
      display: 'inline-block',
      color: '#fff',
      wordBreak: 'break-all',
    },
  },
  mess: {
    display: 'flex',
    flexDirection: 'row',
    margin: 5,
  },
  content: {
    marginLeft: 5,
    '& span': {
      borderRadius: 12,
      padding: 10,
      backgroundColor: '#f1f0f0',
      display: 'inline-block',
      wordBreak: 'break-all',
      textAlign: 'left',
    },
  },
  displayMessages: {
    height: 'calc(100vh - 250px)',
    overflow: 'hidden',
    '&:hover': {
      overflow: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 120px)',
    },
  },
  chatHeader: {
    backgroundColor: '#ff5c33',
    color: 'white',
    height: '35px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

function ChatHome() {
  const classes = useStyles();
  let user = auth.currentUser;
  const {albumId} = useParams();
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isPressed] = useKeyboardJs('enter');

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({behavior: 'smooth'});
  };
  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (isPressed && content) {
      sendMessage();
    }
  }, [isPressed]);

  const sendMessage = async () => {
    setLoading(true);
    const newMessageObj = {
      id: `${new Date().getTime()}`,
      content: content,
      targetId: user.uid,
      chatInfo: {
        avatar: user.photoURL,
        id: user.uid,
        nickName: user.displayName,
      },
      time: `${new Date().getTime()}`,
    };

    firestore.collection('funshare-albums').doc(albumId).collection('messages')
        // .doc(user.uid)
        .add(newMessageObj);
    // updateScroll()
    setContent('');
  };

  const getChatData = () => {
    firestore.collection('funshare-albums').
        doc(albumId).
        collection('messages').
        onSnapshot(function(snapshot) {
          let messages = [];
          snapshot.forEach(function(doc) {
            messages.push(doc.data() || []);
          });
          setMessages(_.orderBy(messages, 'time'));
        });

  };
  useEffect(() => {
    if (albumId)
      getChatData();
  }, [albumId]);

  return (
      <div style={{
        width: '100%',
        borderRadius: '10px 10px 0px 0px',
        border: '2px solid red',
      }}>
        <div className={classes.chatHeader}>
          <Typography component={'h5'} style={{lineHeight: '35px'}}> Chat
            Group </Typography>
        </div>
        <div id={'messages'} className={classes.displayMessages}>
          {messages.map((d, index) => {
            if (!d) return null;
            if (auth.currentUser.uid === d.chatInfo.id) {
              return (
                  <div key={index} className={classes.contentMe}>
                <span>
                  {d.content}
                </span>
                  </div>
              );
            }
            return (
                <div key={index} className={classes.mess}>
                  <Avatar sizes={35} src={d.chatInfo.avatar}/>
                  <div className={classes.content}>
                    <span>{d.content}</span>
                  </div>
                </div>
            );
          })}
          <div ref={messagesEndRef}/>
        </div>

        <div style={{width: '100%'}}>
          <TextField
              placeholder={'type here ...'}
              value={content}
              variant={'outlined'}
              onChange={e => {
                setContent(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                    <IconButton
                        disabled={!content}
                        variant={'contained'}
                        disable={loading}
                        onClick={sendMessage}
                        color={'primary'}
                    >
                      <Send/>
                    </IconButton>
                ),
                // classes: {input: classes.input}
              }}
              fullWidth
              autoFocus={true}
          />
        </div>
      </div>
  );
}

export default ChatHome;

