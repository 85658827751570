import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useGlobal} from 'reactn'
import axios from 'axios'
import _  from 'lodash'
export default function DelePhotoDialog() {
    const [open, setOpen] = useGlobal('opendeletePhoto')
    const [photoSelected] = useGlobal('photoSelected')
    const [album_id] = useGlobal('album_id')
    const [photos, setPhotos] = useGlobal('photos')
    const [delanchorEl] = useGlobal('anchoEl')
    function handleClose() {
        if (delanchorEl) {
            delanchorEl.style.border = ''
        }
        setOpen(false);
    }
    const onDeleted = () =>{
        handleClose()
        axios.post('/photos/delete',{
            album_id,
            photo_id:photoSelected
        }).then(res=>{
            if (res.data) {
                _.remove(photos,p=>p.id === photoSelected)
                setPhotos([...photos])
            }
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Photo Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Xóa không thể phục hồi. Bạn có muốn xóa ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Không
                    </Button>
                    <Button onClick={onDeleted} color="primary" autoFocus>
                        Có
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
