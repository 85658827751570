import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode-react'
import { getGlobal, useGlobal } from 'reactn'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button, Typography } from "@material-ui/core";
import logo from '../Content/angelhackcrownred.jpg'
import { useWindowSize } from 'react-use';
import { isMobileOnly } from "react-device-detect";
import { v4 } from "uuid";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { firestore } from "../../fire";
import { FUNSHARE_IO } from "../../config/url";
import LinearProgress from "@material-ui/core/LinearProgress";

/**
 * @return {null}
 */
function QRcodeGenerator(props) {
  const albumId = getGlobal().selAlbum?.id
  const [lastLink, setLastLink] = useState(null)
  const [copied, setCopied] = useState(false)
  const [user] = useGlobal('user')
  const [shareColl, loading, error] = useCollectionOnce(
    firestore.collection(`funshare-albums/${albumId}/shareColl`)
      .where('byUid', '==', user.uid)
      .orderBy('createdAt', 'desc')
  )
  // const [open] = useGlobal('openDialogScanner');
  const { width, } = useWindowSize();

  useEffect(() => {
    if (lastLink) return;
    if (loading) return;
    if (!loading && shareColl && !shareColl.empty) {
      const shares = shareColl.docs.map(d => ({ id: d.id, ...d.data() }))
      console.log(shares[0].link)
      setLastLink(shares[0].link)
      return
    }
    const code = v4()
    const link = `${FUNSHARE_IO}/share/${albumId}/${code}`
    firestore
      .doc(`funshare-albums/${albumId}/shareColl/${code}`)
      .set({
        byUid: user.uid,
        link,
        code,
        createdAt: new Date,
      })
      .then(() => {
        setLastLink(link)
      })
  }, [lastLink, shareColl, loading, error])

  if (!albumId || !lastLink || loading) return <LinearProgress/>
  if (error) {
    console.log(error.message)
    return null
  }

  return (

    <div style={{ justifyContent: 'center', textAlign: 'center' }}>
      <QRCode
        value={lastLink}
        size={isMobileOnly ? width - 50 : 500}
        logo={logo}

      />
      {/*<Typography component={'h6'}>QRCOde</Typography>*/}
      <div style={{ paddingTop: 20 }}>
        <Typography color={"primary"}>{lastLink}</Typography>
        <CopyToClipboard
          text={lastLink}
          onCopy={() => {
            setCopied(true)
            setTimeout(() => {
              setCopied(false)
            }, 2000)
          }}
        >
          <Button color={"primary"} variant={"outlined"}>Copy</Button>
        </CopyToClipboard>
        {
          copied &&
          <Typography color={"secondary"}> Copied</Typography>
        }
      </div>
    </div>
  );
}

export default QRcodeGenerator;
