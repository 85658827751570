import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HighlightOff from '@material-ui/icons/HighlightOff'
import { firestore } from "../../fire";
import firebase from "firebase/app";

/**
 * @return {null}
 */

export default function DeleteUserDialog({ user, selAlbum }) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onDelete = () => {
    const albumId = selAlbum.id;
    firestore
      .doc(`funshare-albums/${albumId}`)
      .set({
        users: firebase.firestore.FieldValue.arrayRemove(user.uid),
      }, { merge: true })
      .then(() => {
        firestore
          .doc(`funshare-albums/${albumId}/userColl/${user.uid}`)
          .delete()
          .then(() => {
            console.log(`removed ${user.displayName}`)
          })
          .catch((e) => alert(e.message))
      })
      .catch((e) => alert(e.message))

  }

  if (!selAlbum)
    return null

  return (
    <div>
      <HighlightOff variant="outlined"
                    color="primary"
                    fontSize={"small"}
                    onClick={handleClickOpen}/>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">delete {user.displayName} from {selAlbum.topic}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={onDelete} color="primary" autoFocus>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
