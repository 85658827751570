import React  from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useGlobal } from 'reactn'
export default function GotoDialog() {
    const [open, setOpen] = useGlobal('openLinkDialog')
    const [link, setLink] = useGlobal('link')


    function handleClose() {
        setOpen(false)
        setLink('')
    }
    function onGoto() {
        window.location = link
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Album QRCode"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Find {link}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onGoto} color="primary" autoFocus>
                        Go to
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
