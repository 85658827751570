export let API_URL
export let API_WS

export let FUNSHARE_IO
export let FUNSHARE_CLASSFUNC_COM = 'https://funshare.classfunc.com'

switch (process.env.REACT_APP_ENV) {
    case 'development': {
        API_URL = 'http://localhost:2020'
        API_WS = 'ws://localhost:2020'
        FUNSHARE_IO = 'localhost:2019'
        break
    }
    case 'stg': {
        API_URL = 'https://api-stg.funshare.app'
        API_WS = 'wss://api-stg.funshare.app'
        FUNSHARE_IO = 'https://funshare.app'
        break
    }
    default: {//production
        API_URL = 'https://funshare-apis.classfunc.com'
        API_WS = 'wss://funshare-apis.classfunc.com'
        FUNSHARE_IO = 'https://funshare.app'
    }
}
export let CREATE_USER = API_URL + '/user/create'
export let ADD_ALBUM = API_URL + '/albums/create'
export let ADD_USER = API_URL + '/albums/addUser'
export let GET_ALBUM = API_URL + '/albums'
export let GET_IMAGES = API_URL + '/photos'
export let GET_ALBUM_IMAGES = API_URL + '/photos'
export let CREATE_CHAT = API_URL + '/chat/create'
export let GET_MESSAGE = API_URL + '/chat'
