import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {firestore} from "../../fire";
import {useParams} from 'react-router-dom'
import DialogContentText from "@material-ui/core/DialogContentText";

export default function FormDialog({openEdit, setOpenEdit}) {
  const {albumId} = useParams();
  const [topic, setTopic] = useState('')


  const handleClose = () => {
    setOpenEdit(false);
  };

  const onEditName = () => {
    if (albumId)
      firestore
        .collection('funshare-albums')
        .doc(albumId)
        .set({topic: topic}, {merge: true})
    setOpenEdit(false)
    setTopic('')
  }
  return (
    <div>
      <Dialog
        open={openEdit} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Sửa tên Album</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sau khi sửa tên, Album mới sẻ được hiển thị. Bây giờ hãy đặt tên cho Album mới của bạn nhé..!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Tên Album mới của bạn là..."
            type="album"
            variant={"outlined"}
            fullWidth
            value={topic}
            onChange={e => {
              setTopic(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Hủy
          </Button>
          <Button onClick={onEditName} color="primary">
            Đổi tên
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
