import FirebaseCloudStorage from "./FirebaseCloudStorage";
import { storage } from "../../fire";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/webcam/dist/style.css'

const Uppy = require('@uppy/core')
const Webcam = require('@uppy/webcam')

const uppy = Uppy({
  autoProceed: true,
  restrictions: {
    allowedFileTypes: ['image/*', 'video/*']
  }
})

const WebcamOpts = {
  onBeforeSnapshot: () => Promise.resolve(),
  countdown: false,
  title: 'Camera',
  modes: [
    'video-audio',
    'video-only',
    'audio-only',
    'picture'
  ],
  mirror: true,
  facingMode: 'user',
  locale: {
    // Shown before a picture is taken when the `countdown` option is set.
    smile: 'Smile!',
    // Used as the label for the button that takes a picture.
    // This is not visibly rendered but is picked up by screen readers.
    takePicture: 'Take a picture',
    // Used as the label for the button that starts a video recording.
    // This is not visibly rendered but is picked up by screen readers.
    startRecording: 'Begin video recording',
    // Used as the label for the button that stops a video recording.
    // This is not visibly rendered but is picked up by screen readers.
    stopRecording: 'Stop video recording',
    // Title on the “allow access” screen
    allowAccessTitle: 'Please allow access to your camera',
    // Description on the “allow access” screen
    allowAccessDescription: 'In order to take pictures or record video with your camera, please allow camera access for this site.'
  }
}

uppy
  .use(Webcam, WebcamOpts)
  .use(FirebaseCloudStorage, {
    storageRef: storage.ref().child(`funshare/albums`)
  })

export default uppy
