import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import deepOrange from '@material-ui/core/colors/deepOrange'

const styles = {
  avatar: {
    // margin: 10,
    width: 24,
    height: 24,
  },

  orangeAvatar: {
    width: 24,
    height: 24,
    color: '#fff',
    backgroundColor: deepOrange[500],
  },
}

function UserAvatar({classes, text, imgUrl}) {
  let avatarName
  if (text) {
    avatarName = text.substr(0, 1)
  }
  return (
    <div>
      {
        imgUrl ?
          <Avatar className={classes.avatar} src={imgUrl} alt={'user-avatar'}/>
          :
          <Avatar className={classes.orangeAvatar}>{avatarName}</Avatar>
      }
    </div>
  )
}

export default withStyles(styles)(UserAvatar)
